.feedback-container {
    display: table;
    height: 100%;
    width: 100%;
    padding: 0;
  }

.feedback-left {
    display: table-cell;
    text-align: center;
    vertical-align: top;
    width:60%;
    padding-right:0.5rem;
  }

.feedback-right {
    display: table-cell; 
    text-align: center;
    vertical-align:  top;
    width: 40%;
    padding-left:0.5rem;
  }

.fb-hover-bold {
    font-weight: normal;
    transition: font-weight 0.1s ease;  /* Optional: For a smooth transition */
}

.fb-hover-bold:hover {
    font-weight: bold;
    font-size: large
}

/* Container for the rounded rectangles */
.dimension-selector-container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 35px;
  row-gap: 15px;
}

/* Style for the rounded rectangle */
.dimension-selector {
  padding: 25px; /* Add padding to give extra pixels */
  min-width: min-content;
  height: 46px;
  background-color: #F7F7F7;
  border-radius: 5px;
  border-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4F4F4F;
  font-size: 14px;
}

.dimension-grammar {
  background-color: #6C5BF3;
}

.dimension-word {
  background-color: #52BEA4;
}

.dimension-example {
  background-color: #FE7E7B;
}

.dimension-logic {
  background-color: #4C74FF;
}

.dimension-coherence {
  background-color: #FF9E0D;
}

.dimension-pronunciation {
  background-color: #25C0DA;
}

/* Style for the rounded rectangle, selected */
.dimension-selector.dimension-grammar {
  color: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2); /* Add a shadow */
}

/* Style for the rounded rectangle, selected */
.dimension-selector.dimension-word {
  color: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2); /* Add a shadow */
}

/* Style for the rounded rectangle, selected */
.dimension-selector.dimension-example {
  color: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2); /* Add a shadow */
}

/* Style for the rounded rectangle, selected */
.dimension-selector.dimension-logic {
  color: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2); /* Add a shadow */
}

/* Style for the rounded rectangle, selected */
.dimension-selector.dimension-coherence {
  color: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2); /* Add a shadow */
}

/* Style for the rounded rectangle, selected */
.dimension-selector.dimension-pronunciation {
  color: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2); /* Add a shadow */
}

/* Style for the icon */
.dimension-icon {
  margin-right: 10px;
}

.dimension-icon > .selected {
  filter: brightness(9999%)
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.themed-grid-col {
  padding-left: 15px;
  padding-right: 15px;
  text-align: left;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 1em;
  height: 38px;
  display: flex;
  justify-content: space-between; /* Align items to the leftmost and rightmost */
  align-items: center; /* Vertically center the items */
  border-radius: 25px 25px 25px 25px;
}

/* Style for left-aligned items */
.left-items {
  display: flex; /* Nested flex container for left-aligned items */
  align-items: center; /* Vertically center items within the left container */
}

.shallow-dimension-grammar {
  background-color: #E5E1FF;
}

.shallow-dimension-word {
  background-color: #EFFFFB;
}

.shallow-dimension-example {
  background-color: #FDEBF2;
}

.shallow-dimension-logic {
  background-color: #E1E8FF;
}

.shallow-dimension-coherence {
  background-color: #FFF8ED;
}

.shallow-dimension-pronunciation {
  background-color: #ECFCFF;
}

/* Style for the circle */
.circle {
  width: 21px;
  height: 21px;
  border-radius: 50%; /* Makes the element a circle */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}

/* Style for the tick (checkmark) */
.tick {
  width: 12px;
  height: 6px;
  border-bottom: 2px solid #fff; /* White tick */
  border-right: 2px solid #fff; /* White tick */
  transform: translateY(-2px) scaleX(-1) rotate(45deg); /* Rotates the tick */
}

/* Style for the rounded-corner text box container */
.text-box {
  position: relative;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  text-align: left;
  margin: 20px;
  box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.2); /* Add shadow */
}

.topic-boxline {
  position: absolute;
  z-index: 1; 
  background-color: #6c5bf3;
  border-radius: 12px 0px 0px 8px;
  width: 12px;
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
}

.topic-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  position: relative;
  z-index: 2; /* ensures the text is above the rectangle */
  padding-left: 0.5rem;
}

.topic-content {
  font-size: 16px;
  padding-left: 0.5rem;
}

/* Style for the title */
.text-box > .title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}

/* Style for the text content */
.text-box > .content {
  font-size: 16px;
}

.explanation-box {
  background-color: #FAFAFA;
  border-radius: 10px;
  padding: 20px;
  text-align: left;
  margin: 20px;
}

.explanation-box > .title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

.explanation-box > .content {
  font-size: 16px;
  line-height: 2;
}

.total-score {
  float: right;
  font-weight: bold;
  font-size: 16px;
}

.total-score-points {
  font-size: 24px;
  margin-left: 10px;
}

.active-details {
  display: flex;
  align-items: flex-start; /* Align items at the top */
  margin-top: 18px;
}

.details-number {
  font-weight: bold;
  margin-right: 10px;
  transform: translateX(5px);
}

.details-points {
  transform: translateY(-6px);
}

.details-point {
  line-height: 2;
  text-align: left;
}

.details-main-point {
  font-weight: bold;
  font-size: 18px;
}
