.feedbackwindow{
    height: 5rem!important;
}


.star-rating {
    direction: rtl;
    font-size: 40px;
    unicode-bidi: bidi-override;
    display: flex;
    justify-content: center;
  }
  
  .star {
    font-size: 2rem;
    color: gray;
    cursor: pointer;
    padding-left: 5px;
    padding-right: 5px;
  }
  
  .star.filled {
    color: gold;
  }
  
  .star:hover,
  .star:hover ~ .star {
    color: gold;
  }
  