/* body {
    font-family: Arial, sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  } */
  
  #chatbox {
    width: 98%; 
    height: 100%; 
    min-height: 800px;
    /* border: 1px solid #dce3ed; */
    /* overflow-y: scroll; */
    background-color: #fff;
    box-sizing: border-box;
  }

  #chatbox::-webkit-scrollbar {
    width: 0; /* Hide the scrollbar */
}

  .no-scroll{
    overflow-y: none !important;
  }

  .chatbox-title {
    width: 100%;  /* full width of the parent card */
    height: 80px;
    background-color: #f1f5f8;  /* the color of the rectangle */
    position: absolute;  /* position it relative to the card */
    left: 0;  /* align it to the left of the card */

    display: flex;
    align-items: center; /* Vertically aligns the children in the middle */
    justify-content: start; /* This ensures items start from the left. It's the default value so you can omit it */
    gap: 10px; /* This gives space between the text and the .group-64 div */
  }
  
  .container-icon {
    background-color: #0e1424;
    border-radius: 30px;
    height: 50px;
    left: 3%;
    position: relative;
    width: 50px;
  
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ai-icon{
    height: 40px;
    width: 40px;
    left: -20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }

  .user-icon{
    height: 40px;
    width: 40px;
    margin-right: -10px;
    margin-left: 10px;
  }

  .smartprep-TOEFL {
    color: transparent;
    font-family: "Inter", Helvetica;
    font-size: 1.1rem;
    font-weight: 700;
    left: 78px;
    letter-spacing: 0;

    position: absolute;
    white-space: nowrap;
  }
  
 .text-wrapper-22 {
    color: #6c5bf3;
  }
  
 .text-wrapper-23 {
    color: #1c263b;
  }

.chatbox-body {
  padding: 100px 20px;
  top: 100px;
  overflow-y: auto;
}

.massage-container {
  display: flex; 
  align-items: center; 
  display:table;
}

.text-container {
  vertical-align: top; 
  max-width: 350px; 
  padding: 10px; 
  border-radius: 8px; 
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
  background-color: #f1f5f8;; 
  font-family: 'Arial', sans-serif; 
  font-size: 16px; 
  text-align: left;
  margin-bottom: 10px;
}

.user {
  background-color:#f9f4ff;
}

.user-icon, .ai-icon, .text-container{
  display: inline-flex;
}

.text-center{
  display: flex; 
  align-items: center; 
}

.chat-form-group{
  padding-top: 30px;
}

.chat-form-control-wrap {
  position: relative;
  background-color: #f4f4f5;
  border-radius: 50px;
  padding: 5px;
}

.form-control-message {
  background: transparent!important;
  border: none!important; 
  outline: none!important;
  border-radius: 50px!important;
  padding: 10px!important;
  resize: none!important;
  font-size: 16px!important;
}

.chat-custom_text:focus {
  outline: none;
  box-shadow: none; 
}

.send-button{
  padding-top: 30px;
}

.chat-send{
  border: 0px!important;
}

.button-text-wrapper {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  white-space: nowrap;
}

.buttons{
  padding-top:40px;
}

  .timer__part {
    font-size: 36px;
    font-weight: bold;
  }

  /* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2ea68f;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2ea68f;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

Rounded sliders
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

textarea.form-control-message {
  min-height: 0rem !important;
}
