/*.App {
  text-align: center;
  background-color: #f8f9fa;
  height: 80vh;
  width: 80vw;  modified for a better aspect ratio 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #343a40;
}

h1 {
  color: #212529;
  margin-bottom: 1rem;  added to give space between title and form 
}
*/

form {
  width: 100%; /* added to give form full width of the app */
  padding: 1rem; /* added to give some space inside the form */
}

form label {
  margin-right: 0.5rem;
}

/* #chatbox {
  width: 100%;
  height: 100%;
  border: 1px solid #D4D4D4;
  padding: 20px;
  overflow-y: scroll;
  background-color: #E5DDD5;
  border-radius: 10px; increased to match chatbox.css 
} */

.message-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start; /* this is the default value and can be omitted */
}

.message-wrapper.you {
  justify-content: flex-end;
}

.message {
  display: inline-block;
  max-width: 60%; /* adjust to taste */
  padding: 10px;
  margin: 15px 10px;
  border-radius: 7px;
  line-height: 1.5;
  font-size: 1rem;
  word-wrap: break-word;
}

.you .message {
  background-color: #DCF8C6;
  border-bottom-right-radius: 0;
}

.ai .message {
  background-color: #FFFFFF;
  border-bottom-left-radius: 0;
}

/*
button {
  color: #fff;
  background-color: #075E54;
  border-color: #075E54;
  margin-top: 1rem;  added to give some space above the button 
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  font-size: 1rem;
  line-height: 1.5;
}

button:disabled {
  background-color: #6c757d;
  border-color: #6c757d;
}
*/
