.chat-play-audio{
    border: 0px!important;
}

.text-wrapper {
    color: #000000;
    font-family: "Inter", Helvetica;
    font-size: 16px;
    font-weight: 500;

    letter-spacing: 0;
    line-height: normal;
    position: absolute;

    white-space: nowrap;
  }