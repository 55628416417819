.card {
    overflow: hidden;
  }

.blurred-component {
  transition: filter 0.3s; /* Add a smooth transition effect */

  /* Set initial blur value to 0 (no blur) */
  filter: blur(0);
}

/* Apply blur effect on hover */
.no-transform:hover {
  transform: translateY(0px) !important;
}

/* Apply blur effect on hover */
.blurred-component.hovered {
  filter: blur(5px); /* Adjust the blur value as needed */
}
.card-buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: row;
  gap: 50px;
  opacity: 0;
  transition: opacity 0.3s;
}

.blurred-component.hovered + .card-buttons {
  opacity: 1;
}
