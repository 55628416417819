/* 引入字体 */
@import url('https://fonts.googleapis.com/css?family=Patrick+Hand|Dancing+Script|Pacifico&display=swap');

.chinese-title {
    font-size: 4.5em;
    padding-bottom: 10px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); /* Text shadow for a bit of depth */
}

.cute-button {
    padding: 12px 24px;
    margin-top: 12px;
    margin-left: 24px;
    margin-right: 24px;
    border: none;
    border-radius: 50px; /* Rounded corners */
    font-size: 18px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    position: relative;
    overflow: hidden;
}

.cute-button:hover {
    transform: translateY(-2px); /* Small bounce effect when hovering */
}

.cute-button:active {
    transform: translateY(1px); /* Press down effect */
}

.cute-button:before {
    content: '';
    position: absolute;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    top: -30px;
    left: -30px;
    pointer-events: none;
    transition: transform 0.5s ease, opacity 0.5s ease;
    opacity: 0;
}

.cute-button:hover:before {
    transform: scale(2);
    opacity: 1;
}

.landinggif{
    margin-top: 50px;
    width: 50vw;
    height: auto;
}